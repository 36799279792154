﻿import React from 'react'
import { useSelector } from 'react-redux'

import { Grid, Box, Typography } from '@material-ui/core'

import { TutorialGallery, Opener, Contacts, NewsCards } from '../components'

const Home = ({ isMicroStrategyWebAppRunning }) => {
    const isShowroomEnvironment = useSelector(state => state.requests.queries.FETCH_IS_SHOWROOM_ENVIRONMENT?.data)
    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Opener
                isShowroomEnvironment={isShowroomEnvironment}
                image="/images/background.jpeg"
                headlines={['Transparenz schaffen', 'Daten analysieren']}
                isMicroStrategyWebAppRunning={isMicroStrategyWebAppRunning}
            />
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} align="center">
                        <Typography variant="h2">News</Typography>
                        <Box mt={2} />
                        <NewsCards />
                        <Box mt={2} />
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="h2">Tutorials</Typography>
                        <Box mt={2} />
                        <TutorialGallery />
                        <Box mt={2} />
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="h2">Ansprechpartner</Typography>
                        <Box mt={2} />
                        <Contacts />
                        <Box mt={2} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Home
