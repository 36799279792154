﻿import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { AppBar, Toolbar, IconButton, Hidden, Drawer, Box } from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import { SideDrawer, Secret, LinkDuo, InstallPrompt } from '.'
import { drawerWidth } from '../helper/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(theme => ({
    appContainer: {
        minHeight: '100%',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    logoContainer: {
        height: `${theme.mixins.toolbar.minHeight}px`,
        width: '150px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: 'url("/images/TXS_Analytics_Logo_Light.svg")',
    },
    drawerPaper: {
        width: drawerWidth,
        borderRight: 'none',
        height: '100%',
        background: `linear-gradient(200deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 120%);`,
        color: theme.palette.light.light,
    },
    contentContainer: {
        flexGrow: 1,
        maxWidth: '100%',
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        [theme.breakpoints.up('md')]: {
            maxWidth: `calc(100% - ${drawerWidth}px)`,
        },
        overflow: 'hidden',
    },
    content: {
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        minHeight: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
        maxWidth: `calc(100vw - ${drawerWidth}px )`,
        [theme.breakpoints.down('sm')]: {
            maxWidth: `100vw`,
        },
        overflow: 'auto',
    },
    toolbar: theme.mixins.toolbar,
}))

const Layout = ({ children, isMicroStrategyWebAppRunning, isMicroStrategyLibraryRunning }) => {
    const classes = useStyles()
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = useState(false)

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

    const CustomDrawer = () => (
        <SideDrawer
            isMicroStrategyWebAppRunning={isMicroStrategyWebAppRunning}
            isMicroStrategyLibraryRunning={isMicroStrategyLibraryRunning}
        />
    )

    const muiDrawerProps = {
        PaperProps: {
            elevation: 4,
        },
        classes: {
            paper: classes.drawerPaper,
        },
        onClick: e => setMobileOpen(false),
    }

    return (
        <Box display="flex" className={classes.appContainer}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <FontAwesomeIcon icon="bars" />
                    </IconButton>
                    <LinkDuo className={classes.logoContainer} to="/"></LinkDuo>
                    <Secret />
                    <Box flexGrow={1}></Box>
                    <InstallPrompt />
                </Toolbar>
            </AppBar>
            <Box className={classes.drawer}>
                <Hidden mdUp implementation="css">
                    <Drawer
                        {...muiDrawerProps}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <CustomDrawer />
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer {...muiDrawerProps} variant="permanent" open>
                        <CustomDrawer />
                    </Drawer>
                </Hidden>
            </Box>
            <main className={classes.contentContainer}>
                <div className={classes.toolbar} />
                <Box className={classes.content}>{children}</Box>
            </main>
        </Box>
    )
}

Layout.propTypes = {
    children: PropTypes.node,
    isShowRoomEnvironment: PropTypes.bool,
    isMsAppRunning: PropTypes.bool,
    isMsLibraryRunning: PropTypes.bool,
}

export default Layout
