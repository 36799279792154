import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import isExternal from 'is-url-external'
import { Link as MuiLink, Box } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

const LinkDuo = forwardRef(({ to, ...props }, ref) => {
    const theme = useTheme()
    if (!to) return <Box {...props} />
    if (isExternal(to)) {
        return (
            <MuiLink
                href={to}
                {...props}
                style={{ textDecoration: 'none', color: theme.palette.text.primary }}
                target="_blank"
                rel="noopener noreferrer"
            />
        )
    } else {
        return (
            <RouterLink
                to={to}
                style={{ textDecoration: 'none', color: theme.palette.text.primary }}
                {...props}
            />
        )
    }
})

LinkDuo.propTypes = {
    to: PropTypes.string,
}

export default LinkDuo
