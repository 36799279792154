import React, { useEffect, useState } from 'react'
import { IconButton, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const InstallPrompt = () => {
    const theme = useTheme()
    const [installPrompt, setInstallPrompt] = useState(null)
    const [installable, setInstallable] = useState(false)
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', e => {
            // Stash the event so it can be triggered later
            setInstallPrompt(e)
            // Update UI notify the user they can add to home screen
            setInstallable(true)
        })
    }, [])
    return (
        installable && (
            <IconButton
                style={{ color: theme.palette.light.light }}
                onClick={e => {
                    setInstallable(false)
                    installPrompt.prompt()
                    installPrompt.userChoice
                        .then(result => setInstallPrompt(null))
                        .catch(e => console.error(e))
                }}
            >
                <FontAwesomeIcon icon="plus-square"></FontAwesomeIcon>
            </IconButton>
        )
    )
}

export default InstallPrompt
