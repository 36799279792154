import React from 'react'
import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { TutorialVideo } from '.'

const tutorialItems = [
    {
        video: '/video/Anmeldung.mp4',
        thumbnail: '/video/Anmeldung_Thumbnail.png',
        tutorialHeader: 'Anmeldung im Showroom',
        tutorialText: '',
    },
    {
        video: '/video/Starten_eines_Dossiers.mp4',
        thumbnail: '/video/Starten_eines_Dossiers_Thumbnail.png',
        tutorialHeader: 'Starten eines Dossiers',
        tutorialText: '',
    },
    {
        video: '/video/Oeffnen_eines_Reports.mp4',
        thumbnail: '/video/Oeffnen_eines_Reports_Thumbnail.png',
        tutorialHeader: 'Öffnen eines Reports',
        tutorialText: '',
    },
]

const TutorialGallery = () => {
    const theme = useTheme()
    return (
        <Grid container spacing={1} style={{ justifyContent: 'center' }}>
            {tutorialItems.map(item => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={3}
                    style={{ marginBottom: theme.spacing(2) }}
                    key={item.video}
                >
                    <TutorialVideo {...item} />
                </Grid>
            ))}
        </Grid>
    )
}

export default TutorialGallery
