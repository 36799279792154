export const newsItems = [
    {
        title: 'Green Bond Dossier jetzt verfügbar',
        text:
            'Unter „freigegebene Berichte => TXS => Green Bond“ können jetzt die ersten Berichte zu Green Bond Daten inklusive einem Dossier aufgerufen werden. Der Hypothekendeckungsstock enthält auch bereits zum Teil grüne Immobilien.',
        date: '2019-10-10',
    },
    {
        title: 'Showroom Analytics ab jetzt online',
        text:
            'Das Reporting- und Analysetool von TXS kann ab jetzt in einem Showroom selbst erlebt werden:',
        link: '/news/txs_analytics_showroom.md',
        date: '2019-10-08',
    },
]
