﻿import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import useInterval from 'use-interval'

import { CssBaseline } from '@material-ui/core'

import { Layout } from '../components'
import {
    Home,
    DatamartDiagram,
    SwaggerHelp,
    HelpPage,
    Imprint,
    LoginInformation,
    NotFound,
    ErrorPage,
    News,
    LoadingPage,
} from '.'
import {
    fetchRefresh,
    FETCH_MICROSTRATEGY_WEB_APP_URL,
    FETCH_MICROSTRATEGY_LIBRARY_URL,
} from '../actions/actions.system'
import { fetchAppInitialization } from '../actions/actions.index'

const App = () => {
    const dispatch = useDispatch()
    const isShowroomEnvironment = useSelector(state => state.requests.queries.FETCH_IS_SHOWROOM_ENVIRONMENT?.data)
    const microStrategyWebAppUrl = useSelector(state => state.requests.queries.FETCH_MICROSTRATEGY_WEB_APP_URL?.data)
    const microStrategyWebAppUrlPending = useSelector(state => state.requests.queries.FETCH_MICROSTRATEGY_WEB_APP_URL?.pending)
    const microStrategyLibraryUrl = useSelector(state => state.requests.queries.FETCH_MICROSTRATEGY_LIBRARY_URL?.data)
    const microStrategyLibraryUrlPending = useSelector(state => state.requests.queries.FETCH_MICROSTRATEGY_LIBRARY_URL?.pending)
    const microStrategyLibraryUrlError = useSelector(state => state.requests.queries.FETCH_MICROSTRATEGY_LIBRARY_URL?.error)

    useEffect(() => {
        dispatch(fetchAppInitialization())
    }, [dispatch])
    useInterval(() => dispatch(fetchRefresh()), 10000, true)

    const redirect = url => url && url !== '/' && window.location.replace(url)
    const launchAnalytics = () => redirect(microStrategyWebAppUrl)
    const launchMicrostrategyLibrary = () => redirect(microStrategyLibraryUrl)

    const connectionEstablished = !microStrategyLibraryUrlError
    const isMicroStrategyWebAppRunning = connectionEstablished &&
        (microStrategyWebAppUrlPending > 0 || microStrategyWebAppUrl
            ? true
            : false)
    const isMicroStrategyLibraryRunning = connectionEstablished &&
        (microStrategyLibraryUrlPending > 0 || microStrategyLibraryUrl
            ? true
            : false)

    return (
        <Layout
            isMicroStrategyWebAppRunning={isMicroStrategyWebAppRunning}
            isMicroStrategyLibraryRunning={isMicroStrategyLibraryRunning}
        >
            <CssBaseline />
            <Switch>
                <Route
                    exact
                    path="/"
                    render={props => (
                        <Home
                            {...props}
                            isMicroStrategyWebAppRunning={isMicroStrategyWebAppRunning}
                        />
                    )}
                />
                <Route
                    exact
                    path="/datamarts"
                    render={props =>
                        isMicroStrategyWebAppRunning ? (
                            <DatamartDiagram />
                        ) : (
                            <ErrorPage {...props} />
                        )
                    }
                />
                <Route
                    exact
                    path="/start-analytics"
                    render={props =>
                        isMicroStrategyWebAppRunning ? (
                            isShowroomEnvironment ? (
                                <LoginInformation
                                    {...props}
                                    startButtonProps={{
                                        to: microStrategyWebAppUrl,
                                        id: 'externalLinkToMicrostrategy',
                                    }}
                                />
                            ) : (
                                launchAnalytics() || <LoadingPage />
                            )
                        ) : (
                            <ErrorPage {...props} />
                        )
                    }
                />
                <Route
                    exact
                    path="/start-microstrategylibrary"
                    render={props =>
                        isMicroStrategyLibraryRunning ? (
                            isShowroomEnvironment ? (
                                <LoginInformation
                                    {...props}
                                    startButtonProps={{
                                        to: microStrategyLibraryUrl,
                                        id: 'externalLinkToMicrostrategyLibrary',
                                    }}
                                />
                            ) : (
                                launchMicrostrategyLibrary() || <LoadingPage />
                            )
                        ) : (
                            <ErrorPage {...props} />
                        )
                    }
                />
                <Route exact path="/help" component={SwaggerHelp} />               
                <Route exact path="/imprint" component={Imprint} />
                <Route exact path="/news/:id" component={News} />
                <Route component={NotFound} />
            </Switch>
        </Layout>
    )
}

export default App
