import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}))

const ErrorPage = () => {
    const classes = useStyles()
    const theme = useTheme()
    return (
        <Box m={2} className={classes.root}>
            <FontAwesomeIcon icon="tools" size="10x" color={theme.palette.primary.main} />
            <Box m={1} />
            <Typography align="center">TXS Analytics ist vorübergehend nicht verfügbar.</Typography>
        </Box>
    )
}

export default ErrorPage
