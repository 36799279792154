﻿import React, { Component } from 'react'
import { connect } from 'react-redux'

import './DatabaseDiagram.css'
import { jsPlumb } from 'jsplumb'
import { MapInteractionCSS } from 'react-map-interaction'
import { IconButton, NativeSelect, Toolbar, Typography } from '@material-ui/core'
import domtoimage from 'dom-to-image'
import fileSaver from 'file-saver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NotificationManager } from 'react-notifications'
import { fetchProjects, fetchTables, fetchDatamartContent } from '../actions/actions.datamart'

class DatamartDiagram extends Component {
    constructor(props) {
        super(props)

        this.state = {
            projects: [],
            selectedProject: '',
            tables: [],
            selectedTable: '',
            diagram: {},
            visibleTables: [],
            scale: 1,
            translation: { x: 0, y: 0 },
            isLoading: false,
        }
        this._resizeHandler = this._buildDiagram.bind(this)
    }

    // TODO: catch request failure
    componentDidMount() {
        this.props
            .dispatch(fetchProjects())
            .then(({ data }) =>
                this.setState(
                    prevState => {
                        return {
                            ...prevState,
                            open: true,
                            projects: data,
                            selectedProject: data.length === 1 ? data[0].id : '',
                        }
                    },
                    () =>
                        this.state.selectedProject !== '' &&
                        this._handleProjectChange(this.state.selectedProject)
                )
            )
            .catch(e => this.setError(e))

        window.addEventListener('resize', this._resizeHandler)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._resizeHandler)
    }

    setError(error) {
        NotificationManager.warning(
            error.message ||
                'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.'
        )
    }

    _buildDiagram() {
        const canvas = document.getElementById('canvas')
        this._autoLayout()
        jsPlumb.reset()
        jsPlumb.setContainer(canvas)

        // HACK to fix the scrolling/zooming via mousewheel problem
        canvas.parentNode.parentNode.onmousewheel = evt => {
            !evt.ctrlKey && evt.stopPropagation()
        }

        jsPlumb.ready(() => {
            jsPlumb.batch(() => {
                jsPlumb.draggable(jsPlumb.getSelector('.dataTable'), {})
                if (this.state.diagram.connections) {
                    this.state.diagram.connections.forEach(conn =>
                        jsPlumb.connect({
                            source: conn.sourceTable,
                            target: conn.targetTable,
                            anchor: 'Continuous',
                            endpoint: 'Blank',
                            connector: ['Straight', {}],
                            overlays: [
                                ['Arrow', { id: 'arrow', location: 1, width: 10, length: 10 }],
                            ],
                        })
                    )
                }
            })
        })
    }

    _autoLayout() {
        var canvasRect = document.getElementById('canvas').getClientRects()[0],
            tableEls = Array.from(jsPlumb.getSelector('.dataTable')),
            tableElsFiltered = tableEls.filter(tableEl => tableEl.id !== this.state.selectedTable),
            centerTableEl = tableEls.filter(tableEl => tableEl.id === this.state.selectedTable)[0],
            radiusX = canvasRect.width / 2 - 150,
            radiusY = canvasRect.height / 2 - 100,
            degreePerTable = tableEls.length ? 360 / tableElsFiltered.length : 360,
            elCenterX = canvasRect.width / 2 - centerTableEl.clientWidth / 2,
            elCenterY = canvasRect.height / 2 - centerTableEl.clientHeight / 2

        tableElsFiltered.forEach((tableEl, idx) => {
            var tablePosition = this._polarToCartesian(
                elCenterX,
                elCenterY,
                radiusX,
                radiusY,
                idx * degreePerTable
            )

            this._relocateTableEl(tableEl, tablePosition)
        })

        this._relocateTableEl(centerTableEl, { x: elCenterX, y: elCenterY })
    }

    _relocateTableEl(tableEl, tablePosition) {
        tableEl.style.left = tablePosition.x + 'px'
        tableEl.style.top = tablePosition.y + 'px'
    }

    _polarToCartesian(centerX, centerY, radiusX, radiusY, angleInDegrees) {
        const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
        const winkelX = radiusX * Math.cos(angleInRadians)
        return { x: centerX + winkelX, y: centerY + radiusY * Math.sin(angleInRadians) }
    }

    _loadDiagram() {
        this.setState(
            prevState => {
                return { ...prevState, isLoading: true }
            },
            () => jsPlumb.reset()
        )
        let project = this.state.projects.find(p => p.id === this.state.selectedProject)
        this.props
            .dispatch(
                fetchDatamartContent({ project: project.dsn, table: this.state.selectedTable })
            )
            .then(({ data }) =>
                this.setState(
                    prevState => {
                        return { ...prevState, diagram: data, isLoading: false }
                    },
                    () => this._buildDiagram()
                )
            )
            .catch(e => this.setError(e))
    }

    _handleExportDiagram() {
        const diagramEl = document.getElementById('canvasContainer')
        domtoimage
            .toPng(diagramEl)
            .then(pngData => {
                const fileName = 'diagram.png'
                fileSaver.saveAs(pngData, fileName)
            })
            .catch(e => this.setError(e))
    }

    _bringToFront(elId) {
        const tableEls = Array.from(jsPlumb.getSelector('.dataTable'))
        tableEls.forEach(tableEl => {
            tableEl.style.zIndex = tableEl.id === elId ? 101 : 100
        })
    }

    _handleProjectChange(projectId) {
        let project = this.state.projects.find(p => p.id === projectId)
        this.props
            .dispatch(fetchTables(project))
            .then(({ data }) =>
                this.setState(
                    prevState => {
                        return {
                            ...prevState,
                            selectedProject: project.id,
                            tables: data,
                            diagram: {},
                            selectedTable: '',
                            visibleTables: [],
                        }
                    },
                    () => {
                        jsPlumb.repaintEverything()
                    }
                )
            )
            .catch(e => this.setError(e))
    }

    _handleTableChange(tableName) {
        this.setState(
            prevState => {
                return {
                    ...prevState,
                    selectedTable: tableName,
                    visibleTables: [tableName],
                    scale: 1,
                    translation: { x: 0, y: 0 },
                }
            },
            () => this._loadDiagram()
        )
    }

    _handleTableVisibilityChange(tableName) {
        let newVisibleTables =
            this.state.visibleTables.indexOf(tableName) === -1
                ? this.state.visibleTables.concat(tableName)
                : this.state.visibleTables.filter(el => el !== tableName)

        this.setState(
            prevState => {
                return { ...prevState, visibleTables: newVisibleTables }
            },
            () => {
                jsPlumb.repaintEverything()
            }
        )
    }

    _handleResetClick() {
        this.setState(
            prevState => {
                return {
                    ...prevState,
                    scale: 1,
                    translation: { x: 0, y: 0 },
                    visibleTables: [prevState.selectedTable],
                }
            },
            () => this._buildDiagram()
        )
    }

    _renderTable(table) {
        const { visibleTables } = this.state,
            icon = visibleTables.includes(table.name) ? 'window-minimize' : 'window-maximize',
            bodyDisplay = visibleTables.includes(table.name) ? 'inherit' : 'none'

        return (
            <div
                className="dataTable"
                id={table.name}
                key={table.name}
                onClick={() => this._bringToFront(table.name)}
            >
                <div className="headerContainer">
                    <div className="headerLabel">{table.name}</div>
                    <div className="bodyToggle">
                        <FontAwesomeIcon
                            icon={icon}
                            onClick={() => this._handleTableVisibilityChange(table.name)}
                        />
                    </div>
                </div>
                <div className="bodyContainer" style={{ display: bodyDisplay }}>
                    {table.columns.map(column => (
                        <div key={column.name} className={`row ${column.kind}`}>
                            <div className={`column kind ${column.kind}`} />
                            <div className="column name">{column.name}</div>
                            <div className="column type">{column.type}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    render() {
        const {
                projects,
                selectedProject,
                tables,
                selectedTable,
                diagram,
                scale,
                translation,
                isLoading,
            } = this.state,
            ms = window.navigator.msSaveBlob

        return (
            <div className="diagramContainer">
                <Toolbar style={{ justifyContent: 'flex-end', padding: '0px' }}>
                    <IconButton
                        size="small"
                        color="primary"
                        disabled={!diagram.tables}
                        onClick={() => this._handleResetClick()}
                    >
                        <FontAwesomeIcon icon="sync-alt" size="sm" />
                    </IconButton>
                    {!ms && (
                        <IconButton
                            size="small"
                            color="primary"
                            disabled={!diagram.tables || ms}
                            onClick={() => this._handleExportDiagram()}
                        >
                            <FontAwesomeIcon icon="download" size="sm" />
                        </IconButton>
                    )}
                    <NativeSelect
                        className="select"
                        value={selectedProject}
                        onChange={evt => this._handleProjectChange(evt.target.value)}
                    >
                        <option value="" disabled>
                            Wählen Sie ein Projekt
                        </option>
                        {projects &&
                            projects.map(project => (
                                <option key={project.id} value={project.id}>
                                    {project.name}
                                </option>
                            ))}
                    </NativeSelect>
                    <NativeSelect
                        className="select selectTable"
                        value={selectedTable}
                        onChange={evt => this._handleTableChange(evt.target.value)}
                    >
                        <option value="" disabled>
                            Wählen Sie eine Tabelle
                        </option>
                        {tables &&
                            tables.map(table => (
                                <option key={table} value={table}>
                                    {table}
                                </option>
                            ))}
                    </NativeSelect>
                </Toolbar>

                <div id="canvasContainer" className="canvasContainer">
                    <MapInteractionCSS
                        translation={translation}
                        showControls={true}
                        scale={scale}
                        minScale={0.5}
                        maxScale={1.5}
                        plusBtnContents={<FontAwesomeIcon icon="search-plus" />}
                        minusBtnContents={<FontAwesomeIcon icon="search-minus" />}
                        btnClass="scaleButton"
                        onChange={({ scale, translation }) =>
                            this.setState(prevState => {
                                return { ...prevState, scale, translation }
                            })
                        }
                    >
                        <div id="canvas">
                            {isLoading ? (
                                <div className="loadingContainer">
                                    <div className="loadingDiagram" />
                                    <Typography color="primary">Loading...</Typography>
                                </div>
                            ) : (
                                diagram.tables &&
                                diagram.tables.map(table => this._renderTable(table))
                            )}
                        </div>
                    </MapInteractionCSS>
                </div>
            </div>
        )
    }
}

export default connect(
    null,
    null
)(DatamartDiagram)
