import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container, CircularProgress, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import { newsItems } from '../helper/news'
import { NewsDateLabel } from '../components'
import { fetchNews } from '../actions/actions.news'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))

const News = () => {
    const classes = useStyles()
    const { id } = useParams()
    const [markdown, setMarkdown] = useState('')
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const dispatch = useDispatch()

    const { date } = newsItems.find(item => item.link && item.link.includes(id)) || {}

    useEffect(() => {
        dispatch(fetchNews({ id }))
            .then(({ response }) => {
                setMarkdown(response.data)
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false))
    }, [id, dispatch])

    return (
        <Container className={classes.root} maxWidth="md">
            {loading && (
                <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                    <CircularProgress />
                </Box>
            )}
            {error && <Typography>Dieser Artikel konnte nicht gefunden werden.</Typography>}
            <NewsDateLabel date={date} />
            <ReactMarkdown>{markdown}</ReactMarkdown>
        </Container>
    )
}

export default News
