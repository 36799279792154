import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import 'swagger-ui/dist/swagger-ui.css'
import SwaggerUi from 'swagger-ui'

const useStyles = makeStyles(theme => ({
    swaggerContainer: {
        width: '100%',
        '& .swagger-ui .scheme-container': {
            display: 'none',
        },
    },
}))

// TODO: Migrate to swagger-ui react bindings
const SwaggerHelp = () => {
    const classes = useStyles()
    useEffect(() => {
        SwaggerUi({
            dom_id: '#swaggerContainer',
            url: `swagger/v1/swagger.json`,
            presets: [SwaggerUi.presets.apis],
        })
    }, [])

    return <div id="swaggerContainer" className={classes.swaggerContainer} />
}

export default SwaggerHelp
