import React from 'react'
import PropTypes from 'prop-types'
import Textfit from 'react-fittext'
import {
    Box,
    Typography,
    makeStyles,
    Button,
    Paper,
    Grid,
    Link as MuiLink,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundImage: 'url(/images/background.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
    },
    paperWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '100%',
    },
    paperGridItem: {
        maxWidth: '100%',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10vh',
        padding: theme.spacing(3),
    },
    loginContainer: {
        marginTop: theme.spacing(3),
    },
    description: {
        marginTop: theme.spacing(3),
    },
    startButton: {
        marginTop: theme.spacing(3),
        color: `${theme.palette.light.light} !important`,
    },
}))

const LoginInformation = ({ startButtonProps }) => {
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <Grid container className={classes.paperWrapper}>
                <Grid item className={classes.paperGridItem}>
                    <Paper className={classes.paper}>
                        <Typography variant="h2" component={Box} width="100%" maxWidth="100%">
                            <Textfit minFontSize={12} maxFontSize={50} compressor={1.3}>
                                <Box textAlign="center">Anmeldeinformationen</Box>
                            </Textfit>
                        </Typography>
                        <Typography className={classes.description}>
                            Mit diesen Daten können Sie sich auf der nachfolgenden Seite anmelden.
                        </Typography>
                        <Grid container className={classes.loginContainer}>
                            <Grid item xs={6}>
                                Benutzername
                            </Grid>
                            <Grid item xs={6}>
                                guest
                            </Grid>
                            <Grid item xs={6}>
                                Kennwort
                            </Grid>
                            <Grid item xs={6}>
                                showroom123
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            component={MuiLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.startButton}
                            href={startButtonProps.to}
                            {...startButtonProps}
                        >
                            Start
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

LoginInformation.propTypes = {
    startButtonProps: PropTypes.any,
}

export default LoginInformation
