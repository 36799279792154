import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import axiosInstance from './helper/AxiosInstance'
import { createDriver } from '@redux-requests/axios'
import { handleRequests } from '@redux-requests/core'

export default (defaultStore = {}) => {
    const store = setupStore()

    return store
}

const setupStore = () => {
    const { requestsReducer, requestsMiddleware } = handleRequests({
        driver: createDriver(axiosInstance),
    })

    const reducer = combineReducers({
        requests: requestsReducer,
    })

    return configureStore({
        reducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({ serializableCheck: true, immutableCheck: false }).concat(
                requestsMiddleware
            ),
    })
}
