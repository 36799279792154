import React from 'react'
import PropTypes from 'prop-types'
import { Typography, makeStyles, Paper, Grid, Link as MuiLink } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {},
    contactRoot: {
        padding: theme.spacing(3),
    },
}))

const contacts = [
    {
        name: 'Christoph Kupfer',
        title: 'Product Owner',
        phone: '+49 160 96991830',
        mail: 'Christoph.Kupfer@txs.de',
    },
    {
        name: 'Tim Geißler',
        title: 'Mitglied der Geschäftsleitung',
        phone: '+49 40 88150 135',
        mail: 'Tim.Geissler@txs.de',
    },
]

const Contacts = () => {
    const classes = useStyles()
    return (
        <Grid container spacing={1} className={classes.root} style={{ justifyContent: 'center' }}>
            {contacts.map((contact, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Contact {...contact} />
                </Grid>
            ))}
        </Grid>
    )
}

const Contact = ({ name, title, phone, mail }) => {
    const classes = useStyles()
    return (
        <Paper className={classes.contactRoot}>
            <Typography variant="h4">{name}</Typography>
            <Typography variant="subtitle1">{title}</Typography>
            <MuiLink href={`tel:${phone}`}>
                <Typography variant="body1" component="div">
                    {phone}
                </Typography>
            </MuiLink>
            <MuiLink href={`mailto:${mail}`} target="_blank" rel="noopener noreferrer">
                <Typography variant="body1" component="div">
                    {mail}
                </Typography>
            </MuiLink>
        </Paper>
    )
}

Contact.propTypes = {
    mail: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
}

export default Contacts
