import React from 'react'
import PropTypes from 'prop-types'
import { Typography, makeStyles, Paper, Grid, Box, Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LinkDuo, NewsDateLabel } from '.'
import { newsItems } from '../helper/news'

const useStyles = makeStyles(theme => ({
    root: {},
    newsRoot: {
        padding: theme.spacing(3),
        height: `calc(100% - ${theme.spacing(6)}px)`,
        display: 'flex',
        flexDirection: 'column',
    },
}))

const NewsCards = () => {
    const classes = useStyles()

    // Sort news descending, showing the newest first
    const sortedNews = newsItems.sort((a, b) => new Date(a.date) < new Date(b.date))

    return (
        <Grid container spacing={1} className={classes.root} style={{ justifyContent: 'center' }}>
            {sortedNews.map((newsItem, index) => (
                <Grid item xs={12} sm={6} lg={4} key={index} component={LinkDuo} to={newsItem.link}>
                    <NewsItem {...newsItem} />
                </Grid>
            ))}
        </Grid>
    )
}

const NewsItem = ({ title, text, link, date }) => {
    const classes = useStyles()
    return (
        <Paper className={classes.newsRoot}>
            <Box flexGrow="1">
                <Box width="100%" display="flex" alignContent="flex-start">
                    <NewsDateLabel date={date} />
                </Box>
                <Typography variant="h4" align="left">
                    {title}
                </Typography>
                <Typography align="left">{text}</Typography>
            </Box>
            {link && (
                <Box width="100%" display="flex">
                    <Box flexGrow="1"></Box>
                    <Button color="primary">
                        Mehr erfahren <FontAwesomeIcon icon="chevron-circle-right" />
                    </Button>
                </Box>
            )}
        </Paper>
    )
}

NewsItem.propTypes = {
    date: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
}

export default NewsCards
