﻿import React from 'react'
import { useSelector } from 'react-redux'

import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    footer: {
        color: theme.palette.light.light,
        fontVariant: 'all-small-caps',
        margin: theme.spacing(1),
    },
    footerContent: {
        minHeight: '48px',
    },
}))

const Footer = () => {
    const classes = useStyles()
    const version = useSelector(state => state.requests.queries.FETCH_VERSION?.data)

    return (
        <Box display="flex" flexDirection="column" className={classes.footer}>
            <Typography variant="body1" color="inherit" className={classes.footerContent}>
                &copy; {new Date().getFullYear()} TXS GmbH. Alle Rechte vorbehalten.
            </Typography>
            <Typography variant="body1" color="inherit" className={classes.footerContent}>
                {version && version.application}
            </Typography>
        </Box>
    )
}

export default Footer
