import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'

import { dateFormatter } from '../helper/formatting'

const NewsDateLabel = ({ date }) => {
    return date ? <Chip label={dateFormatter(date)} size="small" variant="outlined" /> : null
}

NewsDateLabel.propTypes = {
    date: PropTypes.string,
}

export default NewsDateLabel
