import React from 'react'
import { Container, CircularProgress, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))

const LoadingPage = () => {
    const classes = useStyles()

    return (
        <Container className={classes.root} maxWidth="md">
            <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                <CircularProgress />
            </Box>
        </Container>
    )
}

export default LoadingPage
