import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { HiddenEasterEgg } from "react-hidden-easter-egg";

import { postShowroomReset } from '../actions/actions.system'

const useStyles = makeStyles(theme => ({
    navButton: {
        color: 'white',
        fontWeight: 'bold',
        marginBottom: 0,
    },
}))

const Secret = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const secretResetButtonCode = ['t', 'r', 'o', 'l', 'l']
    return (
        <HiddenEasterEgg code={secretResetButtonCode} resetEggMs={3000}>
            <Button
                color="inherit"
                className={classes.navButton}
                target="_blank"
                onClick={() => dispatch(postShowroomReset())}
                rel="noopener noreferrer"
                id="ButtonResetShowroom"
            >
                <Typography variant="button" gutterBottom className={classes.navButtonContent}>
                    Reset-Button
                </Typography>
            </Button>
        </HiddenEasterEgg >
    )
}

export default Secret
