import '@fontsource/barlow/latin.css'
import '@fontsource/montserrat/latin.css'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faHome,
    faProjectDiagram,
    faChartLine,
    faBook,
    faChevronCircleRight,
    faChevronDown,
    faQuestionSquare,
    faExternalLinkAlt,
    faBars,
    faSyncAlt,
    faSearchPlus,
    faSearchMinus,
    faDownload,
    faStamp,
    faTools,
    faWindowMaximize,
    faWindowMinimize,
    faClock,
    faPlusSquare,
} from '@fortawesome/pro-solid-svg-icons'

export const drawerWidth = 220

const defaultTheme = createMuiTheme()

const palette = {
    primary: {
        light: '#8caac8',
        main: '#004060', // Primary colour for the new website
        contrastText: '#ffffff',
    },
    secondary: {
        main: '#ff9020',
        dark: '#b2480c', // Secondary dark colour for the new website
        contrastText: '#ffffff',
    },
    success: {
        main: '#539546',
        contrastText: '#ffffff',
    },
    info: {
        main: '#8caac8',
        contrastText: '#606060',
    },
    attention: {
        main: '#ffbf14',
        contrastText: '#ffffff',
    },
    warning: {
        main: '#b2480c',
        contrastText: '#ffffff',
    },
    light: {
        light: '#ffffff',
        main: '#f0f0f0',
    },
    dark: {
        light: '#a0a0a0',
        main: '#606060',
        dark: '#000000',
    },
    text: {
        disabled: '#606060',
        hint: '#000000',
        primary: '#000000',
        secondary: '#000000',
    },
}

const fontHead = ['Montserrat', 'sans-serif'].join(',')
const fontBody = ['Barlow', 'sans-serif'].join(',')

const primaryGradient = `linear-gradient(to right, ${palette.primary.main} 0%, ${palette.primary.light} 100%);`
const secondaryGradient = `linear-gradient(to right, ${palette.secondary.main} 0%, ${palette.secondary.dark} 100%);`

export const theme = responsiveFontSizes(
    createMuiTheme({
        palette,
        typography: {
            useNextVariants: true,
            fontFamily: fontBody,
            fontFamilyHead: fontHead,
            fontWeightBold: 700,
            fontWeightRegular: 400,
            fontSize: 16,   
            fontStyle: 'normal'
        },
        overrides: {
            MuiAppBar: {
                colorPrimary: {
                    background: primaryGradient,
                },
            },
            MuiDrawer: {
                paper: {
                    width: `${drawerWidth}px`,
                    maxWidth: `${drawerWidth}px`,
                },
            },
            MuiListItem: {
                root: {
                    flexShrink: 0, // IE11 fix - https://stackoverflow.com/questions/48294014/
                },
            },
            MuiListItemIcon: {
                root: {
                    minWidth: '32px',
                },
            },
            MuiButton: {
                contained: {
                    background: secondaryGradient,
                    color: palette.light.light,
                    '&$disabled': {
                        color: palette.light.light,
                    },
                },
                containedPrimary: {
                    background: primaryGradient,
                },
                label: {
                    '& svg': {
                        marginLeft: defaultTheme.spacing(1),
                    },
                },
            },
            MuiTypography: {
                h1: {
                    fontFamily: fontHead,
                    fontWeight: 'bold',
                },
                h2: {
                    fontFamily: fontHead,
                },
                h3: {
                    fontFamily: fontHead,
                },
                h4: {
                    fontFamily: fontHead,
                },
            },
        },
        mixins: {
            toolbar: {
                minHeight: 64,
                maxHeight: 64,
            },
        },
        props: {
            MuiPaper: { elevation: 0, square: true },
        },
        shape: {
            borderRadius: 32,
        },
        gradient: {
            primary: primaryGradient,
            secondary: secondaryGradient,
        },
    })
)

library.add(
    faHome,
    faProjectDiagram,
    faChartLine,
    faBook,
    faChevronCircleRight,
    faChevronDown,
    faQuestionSquare,
    faExternalLinkAlt,
    faBars,
    faSyncAlt,
    faSearchPlus,
    faSearchMinus,
    faDownload,
    faWindowMinimize,
    faWindowMaximize,
    faStamp,
    faTools,
    faClock,
    faPlusSquare
)
