import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Paper, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ReactPlayer from 'react-player'
import ClampLines from 'react-clamp-lines'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        padding: theme.spacing(1),
    },
    // @see https://medium.com/bleeding-edge/enforcing-an-aspect-ratio-on-an-html-element-in-react-and-css-27a13241c3d4
    // 16x9 aspect ratio
    tutorialVideoOuterWrapper: {
        width: '100%',
        height: 0,
        paddingBottom: '66.66%',
        position: 'relative',
    },
    tutorialVideoInnerWrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        '& .react-player__preview': {
            backgroundSize: 'contain !important',
            backgroundRepeat: 'no-repeat',
        },
        '& .react-player__shadow': {
            background:
                'rgba(0, 0, 0, 0) radial-gradient(at center center, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%) repeat scroll 0% 0% !important',
        },
    },
    tutorialText: {
        marginTop: theme.spacing(1),
    },
}))

const TutorialVideo = ({
    video,
    thumbnail,
    onEnded = () => {},
    tutorialHeader = '',
    tutorialText = '',
}) => {
    const classes = useStyles()
    return (
        <Paper className={classes.root}>
            <Box className={classes.tutorialVideoOuterWrapper}>
                <Box className={classes.tutorialVideoInnerWrapper}>
                    <ReactPlayer
                        url={[{ src: video, type: 'video/mp4' }]}
                        playing={true}
                        volume={0}
                        muted={true}
                        controls={true}
                        width="100%"
                        height="100%"
                        className={classes.tutorialVideo}
                        onEnded={onEnded}
                        light={thumbnail ? thumbnail : false}
                    />
                </Box>
            </Box>
            <article className={classes.tutorialText}>
                <Typography variant="h4" gutterBottom component="div">
                    <ClampLines
                        lines={2}
                        text={tutorialHeader ? tutorialHeader : ''}
                        buttons={false}
                    />
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <ClampLines
                        lines={10}
                        text={tutorialText ? tutorialText : ''}
                        buttons={false}
                    />
                </Typography>
            </article>
        </Paper>
    )
}

TutorialVideo.propTypes = {
    onEnded: PropTypes.func,
    thumbnail: PropTypes.string,
    tutorialHeader: PropTypes.string,
    tutorialText: PropTypes.string,
    video: PropTypes.string,
}

export default TutorialVideo
