import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, makeStyles, Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import Textfit from 'react-fittext'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '60vh',
        padding: theme.spacing(2),
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
    },
    headerContainer: {
        height: '50%',
        width: '100%',
        display: 'flex',
        alignItems: 'end',
    },
    header: {
        width: '100%',
        maxHeight: '100%',
        color: theme.palette.light.light,
        textShadow: `0 0 10px ${theme.palette.dark.dark}`,
        textAlign: 'center',
    },
    buttonContainer: {
        marginTop: '3%',
        height: '40%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonRoot: {
        minWidth: '60%',
        minHeight: '40%',
        borderRadius: '64px',
    },
    buttonLabelContainer: {
        fontSize: '30px',
        display: 'flex',
        flexDirection: 'row',
    },
    buttonLabel: {},
    buttonIcon: {},
    iconRight: {
        marginLeft: theme.spacing(1),
    },
}))

const Opener = ({ image, headlines = [], isShowroomEnvironment, isMicroStrategyWebAppRunning }) => {
    const classes = useStyles()
    return (
        <Box style={{ backgroundImage: `url(${image})` }} className={classes.root}>
            <Box className={classes.headerContainer}>
                <Typography color="primary" className={classes.header} component={Box} variant="h1">
                    <Textfit minFontSize={10} maxFontSize={100} compressor={1.3}>
                        <Box display="flex" flexDirection="column" height="100%" maxHeight="100%">
                            {headlines.map((headline, index) => (
                                <Box key={index}>{headline}</Box>
                            ))}
                        </Box>
                    </Textfit>
                </Typography>
            </Box>
            <Box className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color={isMicroStrategyWebAppRunning ? 'primary' : 'secondary'}
                    size="large"
                    disabled={!isMicroStrategyWebAppRunning}
                    component={Link}
                    target={!isShowroomEnvironment ? '_blank' : null}
                    rel={!isShowroomEnvironment ? 'noopener noreferrer' : null}
                    to="start-analytics"
                    classes={{
                        root: classes.buttonRoot,
                        label: classes.buttonLabelContainer,
                    }}
                >
                    <Box display="flex" flexDirection="column" flexGrow="1" textAlign="center">
                        <Box className={classes.buttonLabel}>
                            {isMicroStrategyWebAppRunning ? 'Zu TXS Analytics' : 'Wartungsarbeiten'}
                        </Box>
                        <Box
                            className={classes.buttonSubLabel}
                            component={Typography}
                            variant="subtitle2"
                        >
                            {isShowroomEnvironment ? 'Showroom' : ''}
                        </Box>
                    </Box>
                    <Box className={classes.buttonIcon}>
                        <FontAwesomeIcon
                            icon={isMicroStrategyWebAppRunning ? 'chevron-circle-right' : 'tools'}
                            className={classes.iconRight}
                        />
                    </Box>
                </Button>
            </Box>
        </Box>
    )
}

Opener.propTypes = {
    headlines: PropTypes.array,
    image: PropTypes.string,
    isShowroomEnvironment: PropTypes.bool,
    isMsAppRunning: PropTypes.bool,
}

export default Opener
