export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const FETCH_TABLES = 'FETCH_TABLES'
export const FETCH_DATAMART_CONTENT = 'FETCH_DATAMART_CONTENT'

export const fetchProjects = () => ({
    type: FETCH_PROJECTS,
    request: {
        url: 'api/datamartdiagram/projects',
    },
})

export const fetchTables = ({ dsn }) => ({
    type: FETCH_TABLES,
    request: {
        url: `api/datamartdiagram/tables?dsn=${dsn}`,
    },
})

export const fetchDatamartContent = ({ project, table }) => ({
    type: FETCH_DATAMART_CONTENT,
    request: {
        url: `api/datamartdiagram/diagram?dsn=${project}&tablename=${table}`,
    },
})
