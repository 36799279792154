import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { LinkDuo, Footer } from '.'

const useStyles = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
    listItemContainer: {
        alignItems: 'flex-start',
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
    },
    listItemTitleContainer: {
        alignItems: 'center',
    },
    listItemIcon: {
        color: theme.palette.light.light,
        padding: theme.spacing(1, 0, 0, 0),
    },
    listItemText: {
        display: 'block',
        width: '100%',
        color: theme.palette.light.light,
    },
    listItemWarning: {
        '&, & div, & div span': {
            color: theme.palette.attention.main,
        },        
    },
    invisible: {
        display: 'none'  
    },
}))

const SideDrawer = ({ isMicroStrategyWebAppRunning, isMicroStrategyLibraryRunning }) => {
    const classes = useStyles()
    const isShowroomEnvironment = useSelector(state => state.requests.queries.FETCH_IS_SHOWROOM_ENVIRONMENT?.data)
    const isDocusaurusAvailable = useSelector(state => state.requests.queries.FETCH_IS_DOCUSAURUS_AVAILABLE?.data)

    const items = [
        {
            title: 'Menü',
            className: clsx(classes.toolbar, classes.listItemTitleContainer),
        },
        { divider: true },
        {
            id: 'linkToStart',
            title: 'Start',
            icon: 'home',
            url: '/',
        },
        {
            id: 'linkToDatamarts',
            title: 'Datamarts',
            icon: isMicroStrategyWebAppRunning ? 'project-diagram' : 'tools',
            url: '/datamarts',
            className: isMicroStrategyWebAppRunning ? '' : classes.listItemWarning,
        },
        {
            id: 'linkToMicrostrategy',
            title: 'Analytics',
            icon: isMicroStrategyWebAppRunning ? 'chart-line' : 'tools',
            url: '/start-analytics',
            newTab: !isShowroomEnvironment && isMicroStrategyWebAppRunning,
            className: isMicroStrategyWebAppRunning ? '' : classes.listItemWarning,
        },
        {
            id: 'linkToMicrostrategyLibrary',
            title: 'Analytics-Library',
            icon: isMicroStrategyLibraryRunning ? 'book' : 'tools',
            url: '/start-microstrategylibrary',
            newTab: !isShowroomEnvironment && isMicroStrategyLibraryRunning,
            className: isMicroStrategyLibraryRunning ? '' : classes.listItemWarning,
        },
        { divider: true },
        {
            title: 'Hilfe',
            className: clsx(classes.toolbar, classes.listItemTitleContainer),
        },
        { divider: true },
        {
            id: 'linkToDocusaurus',
            title: 'Dokumentation',
            icon: 'external-link-alt',
            newTab: true,
            className: isDocusaurusAvailable ? '' : classes.invisible,
            url: '/docusaurus/index.html',
        },
        {
            id: 'linkToMicrostrategy',
            title: 'MicroStrategy',
            icon: 'external-link-alt',
            url:
                'https://www2.microstrategy.com/producthelp/Current/MSTRWeb/WebHelp/Lang_1031/Content/get_started.htm',
        },
        { divider: true },
        {
            title: 'Impressum',
            icon: 'stamp',
            url: '/imprint',
        },
    ]

    return (
        <Box className={classes.listContainer}>
            <List disablePadding className={classes.list}>
                {items.map((element, index) => (
                    <SideDrawerElement {...element} key={index} />
                ))}
                <Box style={{ display: 'flex', flexGrow: '1' }} />
                <Footer />
            </List>
        </Box>
    )
}

const SideDrawerElement = ({
    id,
    title,
    icon,
    url,
    divider = false,
    className,
    newTab = false,
}) => {
    const classes = useStyles()
    if (divider) return <Divider />
    const clickable = url ? true : false
    return (
        <ListItem
            id={id}
            button={clickable}
            component={clickable ? LinkDuo : null}
            target={newTab ? '_blank' : null}
            rel={newTab ? 'noopener noreferrer' : null}
            to={url}
            className={clsx(classes.listItemContainer, className)}
        >
            {icon && (
                <ListItemIcon className={classes.listItemIcon}>
                    <FontAwesomeIcon icon={icon} />
                </ListItemIcon>
            )}
            <ListItemText
                primary={title}
                primaryTypographyProps={{
                    noWrap: false,
                    className: classes.listItemText,
                }}
            />
        </ListItem>
    )
}

SideDrawer.propTypes = {
    isShowRoomEnvironment: PropTypes.bool,
    isMsAppRunning: PropTypes.bool,
    isMsLibraryRunning: PropTypes.bool,
}

SideDrawerElement.propTypes = {
    className: PropTypes.string,
    divider: PropTypes.bool,
    icon: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    newTab: PropTypes.bool,
}

export default SideDrawer
