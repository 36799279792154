﻿import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

import { ThemeProvider } from '@material-ui/styles'
import { register } from 'register-service-worker'

import { App } from './views'
import { theme } from './helper/theme'
import store from './store'

const baseUrl = '/'
const rootElement = document.getElementById('root')

ReactDOM.render(
    <Provider store={store()}>
        <BrowserRouter basename={baseUrl}>
            <ThemeProvider theme={theme}>
                <App />
                <NotificationContainer />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>,
    rootElement
)
