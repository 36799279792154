import axios from 'axios'

const axiosInstance = axios.create({
    headers: {
        Accept: 'application/json',
        'X-Api-Key': '9D54A8975817449B8FDFD1204096EC29',
        'X-Modules': 'WyJCQVNFIiwiR1JFRU5CT05EIl0=', //Dieser string (Lizenz) wird eigentlich von analytics an den MS Proxy �bergeben. Das funktioniert an dieser Stelle nicht. Ist aber auch erst problematisch wenn die Diagramm anzeige auf die lizensierten Module reagieren soll.
        'Content-Type': 'application/json',
    },
    baseURL: '/',
})

export default axiosInstance
