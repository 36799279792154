export const FETCH_VERSION = 'FETCH_VERSION'
export const FETCH_IS_SHOWROOM_ENVIRONMENT = 'FETCH_IS_SHOWROOM_ENVIRONMENT'
export const FETCH_MICROSTRATEGY_WEB_APP_URL = 'FETCH_MICROSTRATEGY_WEB_APP_URL'
export const FETCH_MICROSTRATEGY_LIBRARY_URL = 'FETCH_MICROSTRATEGY_LIBRARY_URL'
export const POST_SHOWROOM_RESET = 'POST_SHOWROOM_RESET'
export const FETCH_IS_DOCUSAURUS_AVAILABLE = 'FETCH_IS_DOCUSAURUS_AVAILABLE'

export const fetchVersion = () => ({
    type: FETCH_VERSION,
    request: {
        url: '/api/system/version',
    },
})

export const fetchIsDocusaurusAvailable = () => ({
    type: FETCH_IS_DOCUSAURUS_AVAILABLE,
    request: {
        url: '/api/system/IsDocusaurusAvailable',
    },
})

export const fetchIsShowroomEnvironment = () => ({
    type: FETCH_IS_SHOWROOM_ENVIRONMENT,
    request: {
        url: '/api/system/IsShowroomEnvironment',
    },
})

export const fetchMicroStrategyWebAppUrl = () => ({
    type: FETCH_MICROSTRATEGY_WEB_APP_URL,
    request: {
        url: '/api/system/MicroStrategyWebApp',
    },
})

export const fetchMicroStrategyLibraryUrl = () => ({
    type: FETCH_MICROSTRATEGY_LIBRARY_URL,
    request: {
        url: '/api/system/MicroStrategyLibraryApp',
    },
})

export const postShowroomReset = () => ({
    type: POST_SHOWROOM_RESET,
    request: {
        method: 'post',
        url: '/api/microstrategy/ResetShowroom',
    },
})

export const fetchRefresh = () => dispatch => {
    return Promise.all([
        dispatch(fetchIsShowroomEnvironment()),
        dispatch(fetchMicroStrategyWebAppUrl()),
        dispatch(fetchMicroStrategyLibraryUrl()),
        dispatch(fetchIsDocusaurusAvailable()),
    ])
}
