import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Iframe from 'react-iframe'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100%',
        width: '100%',
    },
    iframe: {
        border: 'none',
    },
}))

const HelpPage = ({ src, iframeId = 'default' }) => {
    const classes = useStyles()
    return (
        <Grid container className={classes.root}>
            <Iframe
                url={src}
                height="100%"
                width="100%"
                className={classes.iframe}
                id={`help-iframe-${iframeId}`}
            />
        </Grid>
    )
}

HelpPage.propTypes = {
    iframeId: PropTypes.string,
    src: PropTypes.string,
}

export default HelpPage
